import logo from '../assets/icons/svg/spider-logo2.svg';
import { NavLink } from './shared/ui/links/NavLink';
import hamburger from '../assets/icons/svg/hamburger.svg';
import { useState } from 'react';
import { MobileNav } from './shared/ui/mobile-nav/MobileNav';

export function Top(props: {menuOpen: boolean, setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>}) {


    return (
        <div className="top-nav flex between items-center">
            <a href="#">
                <img loading='lazy' src={logo} alt="" />
            </a>
            <div className="nav flex center">
                <NavLink title='portfolio' href='#portfolio'/>
                <NavLink title='clients' href='#clients'/>
                <NavLink title='services' href='#services'/>
                <NavLink title='about us' href='#about-us'/>
                <NavLink title='contact' href='#contact' outline={true}/>
            </div>
            {
                !props.menuOpen &&
                <div onClick={() => props.setMenuOpen(true)} className='mobile-nav-hamburger'>
                    <img loading='lazy' src={hamburger} alt="" />
                </div>
            }
        </div>
    )
}