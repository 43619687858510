import { useEffect, useState } from "react"
import { HeroData } from "../../../data/hero-data";
import imgPlus from '../../../assets/icons/svg/plus.svg';
import imgEquals from '../../../assets/icons/svg/equals.svg';
import spiderBadge from '../../../assets/badges/badge-spider.png';
import smileyBadge from '../../../assets/badges/badge-smiley.png';

export function DynamicLogos() {

    const [currentClient, setCurrentClient] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (currentClient >= HeroData.length - 1) {
                setCurrentClient(0);
            } else {
                setCurrentClient(currentClient+1);
            }
        }, 1000);
    }, [currentClient])

    return (
        <div className="dynamic-logos flex center items-center">
            <img loading='lazy' className="client" src={HeroData[currentClient]} alt="" />
            <img loading='lazy' className="sign" src={imgPlus} alt="" />
            <img loading='lazy' className="badge" src={spiderBadge} alt="" />
            <img loading='lazy' className="sign" src={imgEquals} alt="" />
            <img loading='lazy' className="badge" src={smileyBadge} alt="" />
        </div>
    )
}