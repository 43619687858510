import badge from '../assets/badges/badge-webdev.png';
import werbdesign from '../assets/icons/svg/web-design.svg';
import werbdev from '../assets/icons/svg/web-dev.svg';
import consultation from '../assets/icons/svg/helm.svg';
import maintenance from '../assets/icons/svg/wrench.svg';
import support from '../assets/icons/svg/super.svg';
import salesforce from '../assets/icons/svg/salesforce.svg';
import webapps from '../assets/icons/svg/web-apps.svg';
import ecom from '../assets/icons/svg/ecommerce.svg';
import web3 from '../assets/icons/svg/crypto.svg';

import db from '../assets/icons/svg/database.svg';
import angular from '../assets/icons/svg/angular.svg';
import vue from '../assets/icons/svg/vue.svg';
import react from '../assets/icons/svg/react.svg';
import azure from '../assets/icons/svg/azure.svg';
import csharp from '../assets/icons/svg/csharp.svg';




export const ServicesData = {
    header: {
        title: "Services",
        image: badge,
        content: "We offer peace of mind on a monthly basis, providing support for web applications at a fraction of the cost of hiring employees. Companies trust us for design, front end development, back end development, code refactors, full build outs, training and more."
    },
    chips: [
        {
            logo: werbdesign,
            title: 'Web Design'
        },
        {
            logo: werbdev,
            title: 'Web Development'
        },
        {
            logo: consultation,
            title: 'Consultation'
        },
        {
            logo: maintenance,
            title: 'Maintenance'
        },
        {
            logo: salesforce,
            title: 'Salesforce Dev'
        },
        {
            logo: support,
            title: 'Support'
        },
        {
            logo: webapps,
            title: 'Custom Web Apps'
        },
        {
            logo: ecom,
            title: 'E-Commerce'
        },
        {
            logo:web3,
            title: 'Solana Web3'
        },
    ],
    technologies: [
        db,
        angular,
        vue,
        react,
        azure,
        csharp
    ]
}