export function SectionHeader(props: SectionHeaderProps) {

    return (
        <div className="section-header flex start items-center">
            <img loading='lazy' src={props.image} alt="" />
            <div className="flex column items-start">
                <h1>{props.title}</h1>
                <p>{props.content}</p>
            </div>
        </div>
    )
}

export interface SectionHeaderProps {
    image: string;
    title: string;
    content: string;
}