import badge from '../assets/badges/badge-smiley.png';

export const ClientsData = {
    header: {
        title: "Clients",
        image: badge,
        content: "Web design and development is easy to find these days. Yes, the end result is important, but we focus on the journey. We believe that having a good relationship, along with an organized process, leads to happiness."
    },
    chips: [
        {
            category: "Featured",
            content: [
                "Quest Diagnostics",
                "THX",
                "City of San Francisco",
                "It's It Ice Cream",
                "Beats",
                "FoxFarm Soil & Fertilizer",
                "SF Film Festival",
                "Wells Fargo Securities",
                "Biosearch Technologies",
                "Kaiser Permanente",
                "R&A Cycles",
                "Row52",
                "Felt Bicycles",
                "Aquarium of The Bay",
                "San Francisco Zen Center",
            ]
        },
        {
            category: "Life Science",
            content: [
                "Ameripath",
                "Biosearch Technologies",
                "Gorman Health Group",
                "Machaon Diagnostics",
                "Quest Diagnostics",
                "Rebus Biosystems",
                "Spot The Spot",
                "Affinity MD",
                "Ascend Clinical",
                "Dermpath Diagnostics",
                "Kaiser Permanente",
                "Nocimed",
                "Satellite Healthcare",
                "CenseoHealth",
                "Fluidigm",
                "Westcliff Medical Labs",
                "Parkland Hospital",
                "Sarasota Pathology",
                "Seacliff Diagnostics",
                "UMass Memorial Labs",
            ]
        },
        {
            category: "Commerce",
            content: [
                "Beats",
                "FoxFarm Soil & Fertilizer",
                "It's It Ice Cream",
                "Petland",
                "R&A Cycles",
                "Row52",
                "Regrained",
                "Felt Bicycles",
                "Monster Cable",
                "Aquarian Accessories",
                "Countryman Electronics",
                "Living Tree Community Foods",
                "Zeta Music",
            ]
        },
        {
            category: "Tech",
            content: [
                "Bicycle Financial",
                "Atherton Fiber",
                "Bugcrowd",
                "Influx Data",
                "Netgear",
                "Neumob",
                "Teridion",
                "Yugabyte",
                "SignifAI",
                "Digital Genius"

            ]
        },
        {
            category: "Non Profit",
            content: [
                "Aquarium Of The Bay",
                "Internation Buffalo Council",
                "Seacology",
                "San Francisco Zen Center",
                "SF Silent Film Festival",
                "The Bay Institute",
                "Keep Texas Beautiful",
                "SF Film Festival",
                "Native American Health Center",
                "EAH Housing",
                "Futures Without Violence",
                "American Camellia Society",
                "BAPHR",
                "The Buddhist Peace Fellowship",
                "Spirit Rock",
                "Environmental Defense",
                "The Friendship House",
                "Latino Policy Forum",
                "The Solar Living Institute",

            ]
        },
        {
            category: "San Francisco",
            content: [
                "It's It Ice Cream",
                "THX",
                "City of San Francisco",
                "SF Film Festival",
                "Shimmick Construction",
                "Wells Fargo Securities",
                "Shute Mihaly & Weinberger LLP",
                "Blazing Saddles",
                "Hamilton Zanze",
                "GI Partners",
                "Greens Restaurant",
                "Ferry Building Marketplace",
            ]
        },
        {
            category: "Solana Dev",
            content: [
                "Bonk Coin",
                "Path Protocol",
                "Tensei Ceremony",
                "Cyber Samurai NFT",
                "Crypto Duck Punkz NFT",
                "Da Bois/Da Bobos NFT",
                "CatchKing Explorers NFT",
                "Bet3",
                
                "DojoDb",
            ]
        }
    ] 
}