import badge from '../../../assets/badges/Badge-ggbridge.png';
export function BottomBanner() {

    return (
        <div className="bottom-banner flex items-center">
            <div className="flex column center items-center">
                <img loading='lazy' src={badge} alt="" />
                <p>SF0G98</p>
            </div>
        </div>
    )
}