import { ServicesData } from "../../../data/services-data";
import { SectionHeader } from "../content/SectionHeader";
import { WhiteSection } from "../WhiteSection";
import { ServiceTechnologies } from "./ServiceTechnologies";

export function Services() {
    return (
        <WhiteSection id="services">
            <SectionHeader title={ServicesData.header.title} image={ServicesData.header.image} content={ServicesData.header.content}/>

            <div className="chips flex wrap start align-center">
                {
                    ServicesData.chips.map((chip: any, index: number) => {
                        return <div key={index} className="chip dark">
                            <img loading='lazy' src={chip.logo} alt="" />
                            {chip.title}</div>
                    })
                }
            </div>
            <ServiceTechnologies/>
        </WhiteSection>
    )
}