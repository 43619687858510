import { useEffect } from "react";
import { NavLink } from "../links/NavLink";


export function MobileNav(props: {menuOpen: boolean, setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>}) {

    useEffect(() => {
        if(props.menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [props.menuOpen]);
    function closeModal() {
        props.setMenuOpen(false);
    }
    return (
        <>
            {
                props.menuOpen &&
                <div className="mobile-nav flex column start items-center">
                    <div onClick={closeModal} className="close-btn-mobile">
                        <span  className="close-btn mobile-nav">+</span>

                    </div>
                    <div onClick={closeModal}>
                        <NavLink title='portfolio' href='#portfolio'/>
                    </div>
                    <div onClick={closeModal}>
                        <NavLink title='clients' href='#clients'/>
                    </div>
                    <div onClick={closeModal}>
                        <NavLink title='services' href='#services'/>
                    </div>
                    <div onClick={closeModal}>
                        <NavLink title='about us' href='#about-us'/>
                    </div>
                    <div onClick={closeModal}>
                        <NavLink title='contact' href='#contact'/>
                    </div>
                </div>
            }
    
        </>
    )
}