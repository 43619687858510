import { GreenSection } from "../GreenSection";
import badge from '../../../assets/badges/badge-GG-love.png';
import { DifferentData } from "../../../data/different-data";

export function Different() {
    return (
        <GreenSection id="different">
            <div className="different-content flex between align-center">
                <div className="logo-title flex column start align-start">
                    <img loading='lazy' src={badge} alt="" />
                    <p className="">What makes <br/>us <span>different</span> from other agencies</p>
                    <p className="mobile">What makes us <span>different</span> from other agencies</p>
                </div>
                <div className="list-div flex column center align-start">
                    <p className="">{DifferentData.listTitle}</p>
                    <ul>
                        {
                            DifferentData.listContent.map((listElement: string, index: number) => {
                                return <li key={index}>{listElement}</li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </GreenSection>
    )
}