import React, { useEffect, useState } from 'react';
import './App.css';
import { Top } from './components/Top';
import { Bottom } from './components/Bottom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BottomBanner } from './components/sections/bottom-banner/BottomBanner';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Main } from './pages/Main';
import { MobileNav } from './components/shared/ui/mobile-nav/MobileNav';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-3859680-17');

function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: any[]) => {
      entries.forEach((entry: any) => {
        if(entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
        }
      })
    });
    const toshow = document.querySelectorAll('.card, .chips, div.service-technologies > img');
    toshow.forEach((el: any) => observer.observe(el));
  
  }, []);

  return (
    <BrowserRouter>

      <ParallaxProvider>
        <div className="App">
          
          <Top menuOpen={mobileNavOpen} setMenuOpen={setMobileNavOpen}/>
          {

          }
          <MobileNav menuOpen={mobileNavOpen} setMenuOpen={setMobileNavOpen}/>
          <Routes>
              <Route path="/" element={<Main/>}  />
          </Routes>
          <BottomBanner/>
          <Bottom/>
        </div>
      </ParallaxProvider>
    </BrowserRouter>
  );
}

export default App;
