import { useEffect } from "react";
import { DivProps } from "react-html-props";
import Modal from 'react-modal';

export function SimpleModal({isOpen, setOpen, ...divProps}: SimpleModalProps) {
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          minWidth: "100vw",
          minHeight: "100vh",
          padding: '0px',
          maxWidth: '100vw',
          maxHeight: '100vh',
          overflow: 'hidden'
        },
    };
    Modal.setAppElement('#root');

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isOpen])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            style={customStyles}
        >
            <div
                onClick={() => setOpen(false)} 
                className="">
            </div>
            {divProps.children}
            <span onClick={() => setOpen(false)} className="close-btn">+</span>
        </Modal>
    )
}

export interface SimpleModalProps extends DivProps {
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}