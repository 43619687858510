import mobile from '../assets/icons/svg/mobile.svg';
import airplane from '../assets/icons/svg/airplane.svg';
import pigeon from '../assets/icons/svg/pigeon.svg';
import location from '../assets/icons/svg/location.svg';

export const footerData = {
    contacts: [
        {
            title: "415.221.2176, ext 200",
            link: "tel:4152212176",
            icon: mobile,
            key: 1
        },
        {
            title: "hello@thespider.com",
            link: "mailto:hello@thespider.com",
            icon: airplane,
            key: 2
        },
        {
            title: `Lat: 37.781383\nLong: -122.462363`,
            link: "https://goo.gl/maps/qSNzqhYhgSRqNGfw9",
            icon: pigeon,
            key: 3
        },
        {
            title: `TheSpider, Inc.\n4000 Geary Blvd Suite 200\nSan Francisco, CA 94118`,
            link: "https://goo.gl/maps/qSNzqhYhgSRqNGfw9",
            icon: location,
            key: 4
        },
        {
            title: "Message Sol on Telegram",
            link: "https://t.me/swolsol",
            icon: null,
            key: 5
        }
    ]
}