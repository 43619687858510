import { AboutData } from "../../../data/about-data";
import { CardAbout } from "../../shared/ui/cards/CardAbout";
import { SectionHeader } from "../content/SectionHeader";
import { WhiteSection } from "../WhiteSection";

export function About() {
    return (
        <WhiteSection id="about-us">
            <SectionHeader title={AboutData.header.title} image={AboutData.header.image} content={AboutData.header.content}/>

            <div className="cards flex wrap center items-center">
                {
                    AboutData.images.map((imageCategory: any, index: number) => {
                        return <CardAbout key={index} title={imageCategory.title} images={imageCategory.assets} />
                    })
                }
            </div>
        </WhiteSection>
    )
}