import { ClientModel } from "../models/client-model";
import bicycle from '../assets/cards/Bicycle-App-screens1.jpg';
import fox from '../assets/cards/FF-logo.jpg';
import aqua from '../assets/cards/Aquaqrium-UI.jpg';
import quest from '../assets/cards/Quest-logo1.jpg';
import ra from '../assets/cards/RA-UI-home-bikes.jpg';
import regrained from '../assets/cards/Regrained-UI1.jpg';
import row from '../assets/cards/ROW52-logo1.jpg';
import sfzc from '../assets/cards/SFZC-UI.jpg';
import petland from '../assets/cards/Petland-logo1.jpg';
import buffalo from '../assets/cards/Buffalo-UI.jpg';
import ameri from '../assets/cards/Ameripath-logo.jpg';
import derm from '../assets/cards/Dermpath-logo.jpg';
import sea from '../assets/cards/Seacology-UI1.jpg';
import silent from '../assets/cards/SilentFilm-UI.jpg';
import itsit from '../assets/cards/ITSIT-logo.jpg';
import bio from '../assets/cards/Biosearch-logo.jpg';
import bicylelogo from '../assets/logos/clients/bicycle.png';
import bicard1 from '../assets/cards/Bicycle-App-icons.jpg';
import bicard2 from '../assets/cards/Bicycle-logo1.jpg';

import foxlogo from '../assets/logos/clients/foxfarm.png';
import fox1 from '../assets/cards/FF-UI-homepage.jpg';
import fox2 from '../assets/cards/FF-UI-mobile.jpg';
import fox3 from '../assets/cards/FF-graphic1.jpg';
import fox4 from '../assets/cards/FF-graphic2.jpg';
import fox5 from '../assets/cards/FF-UI-emailer.jpg';

import biologo from '../assets/logos/clients/biosearch2.png';
import bio1 from '../assets/cards/Biosearch-UI-COVID1.jpg';
import bio2 from '../assets/cards/Biosearch-Mobile-About.jpg';
import bio3 from '../assets/cards/Biosearch-icons-standard.jpg';
import bio4 from '../assets/cards/Biosearch-icons-COVID.jpg';
import bio5 from '../assets/cards/Biosearch-hero-COVID.jpg';
import bio6 from '../assets/cards/Biosearch-graphic-COVID.jpg';
import bio7 from '../assets/cards/Biosearch-CC-logo.jpg';

import aqualogo from '../assets/logos/clients/aquarium.png';
import ralogo from '../assets/logos/clients/ra-cycles2.png';
import questlogo from '../assets/logos/clients/quest.png';

import itsitlogo from '../assets/logos/clients/its-it.png';
import itsit1 from '../assets/cards/ITSIT-UI.jpg';

import regrainedlogo from '../assets/logos/clients/regrained2.png';

import rowlogo from '../assets/logos/clients/row52-2.png';
import row1 from '../assets/cards/ROW52-UI-account-marketplace.jpg';
import row2 from '../assets/cards/ROW52-UI-home-lists.jpg';
import row3 from '../assets/cards/ROW52-icons.jpg';
import row4 from '../assets/cards/ROW52-crest.jpg';


import sfzclogo from '../assets/logos/clients/SFZC2.png';
import sfzc1 from '../assets/cards/SFZC-UI-calendar.jpg';

import petlandlogo from '../assets/logos/clients/petland.png';

import buffalologo from '../assets/logos/clients/ITBC2.png';
import buffalo1 from '../assets/cards/Buffalo-icons.jpg';
import buffalo2 from '../assets/cards/Buffalo-logo.jpg';

import amerilogo from '../assets/logos/clients/ameripath2.png';
import ameri1 from '../assets/cards/Ameripath-UI-home.jpg';

import dermlogo from '../assets/logos/clients/dermpath2.png';
import derm1 from '../assets/cards/Dermpath-UI-home.jpg';
import derm2 from '../assets/cards/Dermpath-UI-team.jpg';

import sealogo from '../assets/logos/clients/seacology.png';
import sfsilentlogo from '../assets/logos/clients/silent-film.png';

import machaongraphics from '../assets/cards/machaon-graphics.jpg';
import machaonicons from '../assets/cards/machaon-icons.jpg';
import machaonicons2 from '../assets/cards/machaon-icons2.jpg';
import machaonillustrations from '../assets/cards/machaon-illustrations.jpg';
import machaonlogo from '../assets/cards/machaon-logo.jpg';
import machaonlogo1 from '../assets/cards/machaon-logo1.jpg';
import machaonprint1 from '../assets/cards/machaon-print1.jpg';
import machaonuihomelanding from '../assets/cards/machaon-ui-home-landing.jpg';
import machaonuimobile from '../assets/cards/machaon-ui-mobile.jpg';
import machaonuitestmenu from '../assets/cards/machaon-ui-test-menu.jpg';

export const PortfolioData: ClientModel[] = [
    {
        id:1,
        title: 'MACHA0N DIAGNOSTICS',
        thumbnail: machaonlogo,
        description: "MACHAON DIAGNOSTICS",
        logo: machaonlogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"Logo design.",
                image: machaonlogo,
            },
            {
                description:"Custom illustrations.",
                image: machaonillustrations,
            },
            {
                description:"Web.",
                image: machaonuihomelanding,
            },
            {
                description:"More Web.",
                image: machaonuitestmenu,
            },
            {
                description:"White papers, letterhead, etc.",
                image: machaonprint1,
            },
            {
                description:"Graphics.",
                image: machaongraphics,
            },
            {
                description:"Custom Icons.",
                image: machaonicons,
            },
            {
                description:"Custom Icons Part 2.",
                image: machaonicons2,
            },
            {
                description:"Mobile.",
                image: machaonuimobile,
            },
        ]

    },
    {
        id:2,
        title: 'BICYCLE FINANCIAL',
        thumbnail: bicycle,
        description: "BICYCLE FINANCIAL",
        logo: bicylelogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:'Custom mobile app for this FinTech start up.',
                image:bicycle,
            },
            {
                description:'Custom Icons.',
                image:bicard1,
            },
            {
                description:'Custom Logo.',
                image:bicard2,
            },
        ]
    },
    {
        id:3,
        title: 'FOXFARM',
        thumbnail: fox, 
        description: "FOXFARM",
        logo: foxlogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:'One of our favorite clients! We worked with Fox Farm to relaunch their website and social media.',
                image:fox,
            },
            {
                description:'The challenge was to keep the existing rich vibrant colors that Fox Farm is famous for and translate it to the web.',
                image:fox1,
            },
            {
                description:'Mobile Ready!',
                image:fox2,
            },
            {
                description:'It was a lot of work! Each graphic customized.',
                image:fox3,
            },
            {
                description:'Fox Farm Feeding Schedules Logo',
                image:fox4,
            },
            {
                description:'Emailer.',
                image:fox5,
            },
        ]
    },
    {
        id:4,
        title: 'BIOSEARCH TECHNOLOGIES',
        thumbnail: bio,
        description: "BIOSEARCH TECHNOLOGIES",
        logo: biologo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:'We have been working with Biosearch Technologies for over 20 years! Custom ecommerce, design, logos and integrations.',
                image:bio,
            },
            {
                description:'Covid Campaign.',
                image:bio1,
            },
            {
                description:'Mobile Friendly.',
                image:bio2,
            },
            {
                description:'Custom Icons.',
                image:bio3,
            },
            {
                description:'Covid Custom Icons.',
                image:bio4,
            },
            {
                description:'Covid-19 Campaign.',
                image:bio5,
            },
            {
                description:'Covid-19 Resources Hub Logo.',
                image:bio6,
            },
            {
                description:'Countering Covid Podcast Logo.',
                image:bio7,
            },
        ]
    },
    {
        id:5,
        title: 'AQUARIUM OF THE BAY',
        thumbnail: aqua,
        description: "AQUARIUM OF THE BAY",
        logo: aqualogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:'Aquarium of the Bay, our client from 2007 to 2014. Custom design and custom ticketing, membership solution.',
                image:aqua,
            },
        ]
    },
    {
        id:6,
        title: 'QUEST DIAGNOSTICS',
        thumbnail: quest, 
        description: "QUEST DIAGNOSTICS",
        logo: questlogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"For the past 20 years, TheSpider has built and supported three of Quest Diagnostic's customer facing web applications and multiple business units.",
                image: quest,
            },
        ]

    },
    {
        id:7,
        title: 'R&A CYCLES',
        thumbnail: ra,
        description: "R&A CYCLES",
        logo: ralogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"RACycles.com. We built a custom e-commerce solution for one of the largest bicycle retailers on the web.",
                image: ra,
            },
        ]
    },
    {
        id:8,
        title: 'IT\'S IT',
        thumbnail: itsit,
        description: "IT\'S IT",
        logo: itsitlogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"Custom e-commerce solution for this local bay area favorite, It's It Ice Cream.",
                image: itsit,
            },
            {
                description:"Order some It's It Ice Cream today!",
                image: itsit1,
            },
        ]

    },
    {
        id:9,
        title: 'REGRAINED',
        thumbnail: regrained,
        description: "REGRAINED",
        logo: regrainedlogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"Regrained.com. We built this customized Shopify site to sell sustainable snacks that use upcycled ingredients.",
                image: regrained,
            },
        ]
    },
    {
        id:10,
        title: 'ROW52',
        thumbnail: row,
        description: "ROW52",
        logo: rowlogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"Regrained.com. We built this customized Shopify site to sell sustainable snacks that use upcycled ingredients.",
                image: row,
            },
            {
                description:"Marketplace. Users can pull parts and sell them to other users.",
                image: row1,
            },
            {
                description:"The site is feature rich, with alerts.",
                image: row2,
            },
            {
                description:"Custom Row52 crest logo.",
                image: row4,
            },
            {
                description:"Custom Icons.",
                image: row3,
            },
        ]

    },
    {
        id:11,
        title: 'SAN FRANCISCO ZEN CENTER',
        thumbnail: sfzc,
        description: "SAN FRANCISCO ZEN CENTER",
        logo: sfzclogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"We were lucky to work with this incredible organization!",
                image: sfzc,
            },
            {
                description:"We were lucky to work with this incredible organization!",
                image: sfzc1,
            },
        ]

    },
    {
        id:12,
        title: 'PETLAND',
        thumbnail: petland,
        description: "PETLAND",
        logo: petlandlogo,
        currentImageIndex: 0,
        active: true,
        images: [
            {
                description:"We built a custom e-commerce application for Petland, allowing each franchise the ability to easily re-order products.",
                image: petland,
            }
        ]
    },
    {
        id:13,
        title: 'INTERTRIBAL BUFFALO COUNCIL',
        thumbnail: buffalo,
        description: "INTERTRIBAL BUFFALO COUNCIL",
        logo: buffalologo,
        currentImageIndex: 0,
        active: false,
        images: [
            {
                description:"The Intertribal Buffalo Council. We were very happy to help this organization have an online presence.",
                image: buffalo,
            },
            {
                description:"Custom Icons.",
                image: buffalo1,
            },
            {
                description:"This is a vector version that we created of their existing logo.",
                image: buffalo2,
            },
        ]

    },
    
    {
        id:14,
        title: 'AMERIPATH',
        thumbnail: ameri,
        description: "AMERIPATH",
        logo: amerilogo,
        currentImageIndex: 0,
        active: false,
        images: [
            {
                description:"Ameripath, one of many business units that we have managed for Quest Diagnostics.",
                image: ameri,
            },
            {
                description:"Ameripath, one of many business units that we have managed for Quest Diagnostics.",
                image: ameri1,
            },
        ]

    },
    {
        id:15,
        title: 'DERMPATH DIAGNOSTICS',
        thumbnail: derm,
        description: "DERMPATH DIAGNOSTICS",
        logo: dermlogo,
        currentImageIndex: 0,
        active: false,
        images: [
            {
                description:"We built and managed the corporate website for over 12 years. A fantastic company.",
                image: derm,
            },
            {
                description:"Ameripath, one of many business units that we have managed for Quest Diagnostics.",
                image: derm1,
            },
            {
                description:"Ameripath, one of many business units that we have managed for Quest Diagnostics.",
                image: derm2,
            }
        ]
    },
    {
        id:16,
        title: 'SEACOLOGY',
        thumbnail: sea,
        description: "SEACOLOGY",
        logo: sealogo,
        currentImageIndex: 0,
        active: false,
        images: [
            {
                description: "Seacology, a fantastic non-profit organization.",
                image: sea
            }
        ]

    },
    {
        id:17,
        title: 'SF SILENT FILM FESTIVAL',
        thumbnail: silent,
        description: "SF SILENT FILM FESTIVAL",
        logo: sfsilentlogo,
        currentImageIndex: 0,
        active: false,
        images: [
            {
                description: "The San Francisco Silent Film Festival.",
                image: silent
            }
        ]
    }
]