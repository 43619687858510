import badge from '../assets/badges/badge-spider.png';
import sol1 from '../assets/cards/small/sol-giants.jpg';
import sol2 from '../assets/cards/small/spider-sol.jpg';
import sol3 from '../assets/cards/small/striper.jpg';

import family1 from '../assets/cards/small/familysquare.jpg';
import family2 from '../assets/cards/small/green-couch.jpg';
import family3 from '../assets/cards/small/pizza.jpg';
import family4 from '../assets/cards/small/sushi.jpg';
import family5 from '../assets/cards/small/five.jpg';

import hooky1 from '../assets/cards/small/giantsgame.jpg';
import hooky2 from '../assets/cards/small/spider-clement.jpg';
import hooky3 from '../assets/cards/small/bus.jpg';
import hooky4 from '../assets/cards/small/spider-justin.jpg';

import jeff1 from '../assets/cards/small/jefferson-enzo.jpg';
import jeff2 from '../assets/cards/small/jeffo-guitar.jpg';

import utbm from '../assets/cards/small/utbm.jpg';

import circa1 from '../assets/cards/small/nadine.jpg';
import circa2 from '../assets/cards/small/luc.jpg';

import sf1 from '../assets/cards/small/spider-twin-peaks.jpg';
import sf2 from '../assets/cards/small/4atbridge.jpg';
import sf3 from '../assets/cards/small/atbridge.jpg';
import sf4 from '../assets/cards/small/frenchies-bridge.jpg';
import sf5 from '../assets/cards/small/jeffo-nadine.jpg';
import sf6 from '../assets/cards/small/spider-camille-clement-thomas2.jpg';
import sf7 from '../assets/cards/small/spider-dolores-park.jpg';

import visit1 from '../assets/cards/small/spider-sign.jpg';
import visit2 from '../assets/cards/small/adam-office.jpg';
import visit3 from '../assets/cards/small/conference-room2.jpg';

export const AboutData = {
    header: {
        title: "About Us",
        image: badge,
        content: "TheSpider started as a hobby in 1998 and here we are still weaving after all these years. Over 20 years ago, we had to build our own tools because the current off the shelf products did not exist. It forced us to get good at custom web development. We are a passionate team of pros who happen to get paid for the work we love."
    },
    images: [
        {
            title: "Sol the founder",
            assets: [
                sol1, sol2, sol3
            ]
        },
        {
            title: "The spider family",
            assets: [
                family1, family2, family3, family4, family5
            ]
        },
        {
            title: "Playing hooky",
            assets: [
                hooky1, hooky2, hooky3, hooky4
            ]
        },
        {
            title: "Jefferson, creative director",
            assets: [
                jeff1, jeff2
            ]
        },
        {
            title: "Partnership w/ UTBM",
            assets: [
                utbm
            ]
        },
        {
            title: "En circa 2014",
            assets: [
                circa1, circa2
            ]
        },
        {
            title: "We love SF!",
            assets: [
                sf1, sf2, sf3, sf3, sf4, sf5, sf6, sf7
            ]
        },
        {
            title: "Come visit the spider",
            assets: [
                visit1, visit2, visit3
            ]
        },
    ]
}