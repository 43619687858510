import { useState } from "react";
import { ClientsData } from "../../../data/clients-data";
import { SectionHeader } from "../content/SectionHeader";
import { GreenSection } from "../GreenSection";

export function Clients() {

    const [selectedCategory, setSelectedCategory] = useState(ClientsData.chips[0].category);

    return (
        <GreenSection id="clients">
            <SectionHeader title={ClientsData.header.title} image={ClientsData.header.image} content={ClientsData.header.content}/>
            <div className="filters flex start align-center">
                {
                    ClientsData.chips.map((chip: any, index: number) => {
                        return <button
                            key={index} 
                            className={`filter-btn ${chip.category === selectedCategory ? 'selected' : ''}`}
                            onClick={() => setSelectedCategory(chip.category)}
                            >{chip.category}</button>
                    })
                }
            </div>
            <div className="chips flex wrap start align-center">
                {
                    ClientsData.chips.find((chip: any) => chip.category === selectedCategory)?.content.map((chip: any, index: number) => {
                        return <div key={index} className='chip clear'>{chip}</div>
                    })
                }
            </div>
        </GreenSection>
    )
}