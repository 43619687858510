import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import heroImg from '../../../assets/hero/hero-nebula.jpg';
import heroGridImg from '../../../assets/hero/web-grid1.png';
import { DynamicLogos } from "./DynamicLogos";
import heart from '../../../assets/icons/svg/heart.svg';

export function Hero() {

    return (
        <ParallaxBanner className="banner flex items-center" style={{ aspectRatio: '2 / 1' }}>
            <ParallaxBannerLayer image={heroImg} speed={35} />
            <ParallaxBannerLayer image={heroGridImg} speed={-10} />

            <DynamicLogos />
            <p className="hero-title flex center align-center">WEB 2/3 APPS MADE WITH &nbsp;<img loading='lazy' src={heart} alt="" /></p>
           
        </ParallaxBanner>
            
        
    )
}