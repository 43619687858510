import { ClientModel } from "../../../../models/client-model";

export function Card(props: CardProps) {

    return (
        <div className="card" onClick={props.onClick}>
            <div className="content">
                <img loading='lazy' src={props.clientModel.thumbnail} alt="" />
                <div className="description">
                    <p>
                    {props.clientModel.description}
                    </p>
                </div>
            </div>
            <p className="title">{props.clientModel.title}</p>
        </div>
    )
}


export interface CardProps {
    clientModel: ClientModel;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}
