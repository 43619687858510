import { ServicesData } from "../../../data/services-data";

export function ServiceTechnologies() {

    return (
        <div className="flex center gap items-center service-technologies">
            {
                ServicesData.technologies.map((technology: string, index: number) => <img key={index} loading="lazy" src={technology} alt="" />)
            }
        </div>
    )
}