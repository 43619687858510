import { About } from "../components/sections/about/About";
import { Clients } from "../components/sections/clients/Clients";
import { Different } from "../components/sections/different/Different";
import { Hero } from "../components/sections/hero/Hero";
import { Portfolio } from "../components/sections/portfolio/Portfolio";
import { Services } from "../components/sections/services/Services";

export function Main() {
    

    return (
        <>
        <Hero/>
        <Portfolio/>
        <Clients/>
        <Services/>
        <Different/>
        <About/>
        </>
    )
}