import { SectionProps } from './WhiteSection';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import grid from '../../assets/sections/web-grid2.png'

export function GreenSection({id, ...divProps}: SectionProps) {

    return (
        <div id={id} className="section green flex column items-start">
            <img loading='lazy' className='grid' src={grid} alt="" />
           
            {divProps.children}
        </div>
    )
}