import { useState } from "react";

export function CardAbout(props: CardAboutProps) {


    const [currentImgIndex, setCurrentImageIndex] = useState(0);
    
    function changeImage() {
        

        if(currentImgIndex === props.images.length - 1) {
            setCurrentImageIndex(0);
        } else {
            setCurrentImageIndex(currentImgIndex+1);
        }
    }

    return (
        <div className="card team">
            <div className={`content ${props.images.length > 1 ? 'multi' : ''}`}>
                <img loading='lazy' 
                    onClick={() => {
                        changeImage();
                    }}
                    src={props.images[currentImgIndex]} 
                    alt=""  
                    />
            </div>
            <p className="title">{props.title}</p>
        </div>
    )
}

export interface CardAboutProps {
    title: string;
    images: string[];
}