import spider from '../assets/logos/thespider/footer-spider-blurry.png';
import badge from '../assets/badges/badge-madeinSF.png';
import { footerData } from '../data/footer-data';

export function Bottom() {
    return (
        <div id="contact" className="bottom flex column start items-center">
            <div className='flex column center items-center'>
                <img loading='lazy' src={spider} alt="" />
                <p>Let's Create</p>
                {/* <button className='main-btn'>DROP US A NOTE</button> */}
            </div>
            <div className='footer-links flex between items-start'>
                <div className='flex start'>
                    <div>
                        {
                            footerData.contacts.map((data: any) => {
                                return <a key={data.key} href={data.link}>{data.icon ? <img loading='lazy' src={data.icon} alt=""/> : '' }{data.title}</a>
                            })
                        }
                    </div>
                </div>
                <img loading='lazy' src={badge} alt="" />
            </div>

            <p>Copyright © 1998-{(new Date().getFullYear())} TheSpider, Inc</p>
        </div>
    )
}