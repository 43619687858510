import client1 from '../assets/logos/clients/beats.png';
import client2 from '../assets/logos/clients/aquarium.png';
import client3 from '../assets/logos/clients/foxfarm.png';
import client4 from '../assets/logos/clients/quest.png';
import client5 from '../assets/logos/clients/THX2.png';
import client6 from '../assets/logos/clients/wellsfargo.png';
import client7 from '../assets/logos/clients/seacology.png';
import client8 from '../assets/shake.png';


export const HeroData = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
    // client8
]