export function NavLink(props: NavLinkProps) {

    return (
        <a href={props.href} className={`nav-link ${props.outline ? 'outline' : ''}`}>
            { props.title }
        </a>
    )
}

export interface NavLinkProps {
    title: string;
    href: string;
    outline?: boolean | undefined;
}