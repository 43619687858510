import { useEffect, useRef, useState } from "react";
import { PortfolioData } from "../../../data/portfolio-data";
import { ClientModel } from "../../../models/client-model";
import { Card } from "../../shared/ui/cards/Card";
import { SimpleModal } from "../../shared/ui/modals/Modal";
import { WhiteSection } from "../WhiteSection";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'
import spiderlogo from '../../../assets/logos/thespider/logo.png';
import shareImg from '../../../assets/icons/svg/share.svg';
import { IconContext } from "react-icons";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

import { useLocation } from 'react-router';
import queryString from 'query-string';

export function Portfolio() {

    const [openModal, setOpenModal] = useState(false);
    const [modalHasInit, setModalHasInit] = useState(false);


    const [clients, setClients] = useState(PortfolioData.filter((data: ClientModel) => data.active));
    const [currentClient, setCurrentClient] = useState({} as ClientModel);
    
    const location = useLocation();
    const [currentPorfolio, setCurrentPortfolio] = useState(queryString.parse(location.search))

    useEffect(() => {
        if (currentClient.thumbnail) {
            var newArray: ClientModel[] = [];
            var index = currentClient.id - 1;
            for(var i = 0; i < clients.length; i++) {
                if(index < clients.length) {
                    newArray.push(clients[index++]);
                } else {
                    index=0;
                    newArray.push(clients[index++]);
                }
            }
            setClients(newArray);
            setOpenModal(true);
            setModalHasInit(true);
        }
    }, [currentClient]);

    useEffect(() => {
        if(currentPorfolio.client !== undefined && currentPorfolio.image !== undefined) {
            const clientFromUrl = clients.find((client: ClientModel) => client.id === Number(currentPorfolio.client)) ?? {} as ClientModel;
            if(!clientFromUrl) return;
            setClients([...clients.map((client: ClientModel) => {
                if(client.id === clientFromUrl.id) {
                    return {...client, currentImageIndex: Number(currentPorfolio.image) ?? 0}
                }
                return client
            })]);
            setCurrentClient(clientFromUrl);
        }
    }, []);

    useEffect(() => {
        if(!openModal && modalHasInit) {
            window.history.replaceState(null, "San Francisco Web Design + Development | TheSpider, Inc.", "/");
            setCurrentClient({} as ClientModel);
            setClients(PortfolioData.filter((data: ClientModel) => data.active));
        }
    }, [openModal]);

    function share(text: string, description: string, clientId: number, imageId: number) {
        navigator.share({
            title: 'The Spider x ' + text,
            text: 'The Spider x ' + text + ' - ' + description,
            url: 'https://thespider.com#portfolio' + '?client=' + clientId + '&image=' + imageId
        })
    }

    const SlickArrowLeft = ({ ...props }) => (
        <div
          {...props}
          className={
            "slick-prev slick-arrow"
          }
        >
            <div className="arrow-left">
                        <IconContext.Provider value={{ style: { fontSize: '35px', cursor: 'pointer' } }}>
                            <BsArrowLeft />
                        </IconContext.Provider>
                    </div>
        </div>
      );
      const SlickArrowRight = ({ ...props }) => (
        <div
          {...props}
          className={
            "slick-next slick-arrow"
          }
        >
            <div className="arrow-right">
                        <IconContext.Provider value={{ style: { fontSize: '35px', cursor: 'pointer' } }}>
                            <BsArrowRight />
                        </IconContext.Provider>
                    </div>
        </div>
      );



    return (
        <WhiteSection id="portfolio">
            <div className="clients-list flex center align-center wrap">
                {
                    clients.map((clientData: ClientModel, index: number) => {
                        return (
                            <Card onClick={() => {setCurrentClient(clientData)}} key={index + 'card'} clientModel={clientData} />
                        )
                    })
                } 
            </div>  
            <SimpleModal  isOpen={openModal} setOpen={setOpenModal}> 
                <div className="modal-content">
                    
                    
                    <Slide easing="ease" autoplay={false} transitionDuration={500} prevArrow={<SlickArrowLeft />} nextArrow={<SlickArrowRight />} onChange={() => window.history.replaceState(null, "San Francisco Web Design + Development | TheSpider, Inc.", "/")}>
                        {
                            clients.map((clientSlide: ClientModel, index: number) => {
                                return (
                                    <div className="inside" key={index}>
                                        <div className="top-content flex items-center between">
                                            <div className="logos flex center items-center">
                                                <img loading='lazy' src={spiderlogo} alt="" />
                                                <img loading='lazy' src={clientSlide.logo} alt="" />
                                            </div>
                                            <button
                                                onClick={() => share(clientSlide.title, clientSlide.description, clientSlide.id, clientSlide.currentImageIndex)} 
                                                className="share-btn flex column center items-center">
                                                <img loading='lazy' src={shareImg} alt="" />
                                                SHARE
                                            </button>
                                        </div>
                                        <img loading='lazy' className="main" src={clientSlide.images[clientSlide.currentImageIndex]?.image} alt="" />
                                        <div>
                                            <p className="image-description">{clientSlide.images[clientSlide.currentImageIndex]?.description}</p>
                                            {
                                                <div className="flex center items-center other-images">
                                                    {
                                                        clientSlide.images.length > 1 &&
                                                        clientSlide.images.map((image: {description: string, image: string}, index: number) => {
                                                            return (
                                                                <img loading='lazy' 
                                                                    className={`small ${clientSlide.currentImageIndex === index ? 'selected': ''}`}
                                                                    onClick={() => setClients([...clients.map((client: ClientModel) => {
                                                                        if(client.id === clientSlide.id) {
                                                                            return {...client, currentImageIndex: index}
                                                                        }
                                                                        return client
                                                                    })])} 
                                                                    key={index} 
                                                                    src={image.image} 
                                                                    alt="" />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slide>
                </div>
            </SimpleModal>
        </WhiteSection>
    )
}