import swimlane from '../../assets/icons/svg/swimlane-bottom5.svg';
import { DivProps } from "react-html-props";

export function WhiteSection({id, ...divProps}: SectionProps) {

    return (
        <div id={id} className="section white">
            <img loading='lazy' className='swimlane' src={swimlane} alt="" />
            {divProps.children}
        </div>
    )
}

export interface SectionProps extends DivProps {
    id: string;
}